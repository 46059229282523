<template>
    <centered-layout>
        <w-auth-forgotten-password-form
            :description-text="description"
            :errors="forgottenPasswordErrors"
            :hide-secondary-button="true"
            :loading="loading"
            :logo-src="clientLogo"
            :title="title"
            @submit="submit"
            v-if="!sentEmail"
        >
            <template slot="actions">
                <router-link :to="{name: loginPage}" class="left">
                    Do you want to login?
                </router-link>
            </template>
        </w-auth-forgotten-password-form>
        <w-form-message :logo-path="clientLogo" :title="title" v-else>
            <p>If your email address exists in our system, an email will be sent to you to reset your password.</p>
            <template slot="actions">
                <router-link :to="{name: loginPage}">Do you want to login?</router-link>
            </template>
        </w-form-message>
    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import {LOGIN_PAGE} from '@/router/route-names'
    import CenteredLayout from '@/layouts/CenteredLayout.vue'
    import WAuthForgottenPasswordForm from '@wf/components/WAuthForgottenPasswordForm'
    import WFormMessage from '@wf/components/WFormMessage'

    export default {
        name: 'ForgottenPasswordPage',
        metaInfo () {
            const vm = this
            return {
                title: this.loading ? 'Authorising Request' : vm.title
            }
        },
        data: () => ({
            description: 'Please enter your email address below to request a password reset link.',
            forgottenPasswordErrors: [],
            loading: false,
            sentEmail: false,
            loginPage: LOGIN_PAGE
        }),
        components: {
            CenteredLayout,
            WAuthForgottenPasswordForm,
            WFormMessage
        },
        methods: {
            ...mapActions('auth', ['forgottenPassword']),
            submit (data) {
                const vm = this
                vm.loading = true
                vm.forgottenPassword(data.email_address).then(() => {
                    vm.sentEmail = true
                    vm.loading = false
                }, (response) => {
                    vm.forgottenPasswordErrors = response.errors
                    vm.loading = false
                })
            }
        },
        computed: {
            ...mapGetters('auth', ['clientLogo', 'clientName']),
            title () {
                return `Forgotten Password - ${this.clientName}`
            }
        }
    }
</script>
